var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        width: "650",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "overflow-hidden", attrs: { color: "fill", flat: "" } },
        [
          _c("v-card-title", { class: _vm.title }, [_vm._v("Ad Clone")]),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "7" } },
                [
                  _c("v-text-field", {
                    class: _vm.body,
                    attrs: {
                      rules: _vm.adNameRules,
                      "background-color": "input",
                      label: "New Ad Name",
                      autocomplete: "off",
                      dense: "",
                      outlined: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.ad.ad_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ad, "ad_name", $$v)
                      },
                      expression: "ad.ad_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu1",
                      attrs: {
                        "close-on-content-click": false,
                        bottom: "",
                        "nudge-top": "25",
                        "nudge-left": 10,
                        transition: "scale-y-transition",
                        "offset-y": "",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      class: _vm.body,
                                      attrs: {
                                        value: _vm.formattedStart,
                                        outlined: "",
                                        dense: "",
                                        "background-color": "input",
                                        label: "Start Date",
                                        "prepend-inner-icon": "mdi-calendar",
                                        rules: [
                                          (v) => !!v || "Start Date Required",
                                        ],
                                        readonly: "",
                                        clearable: "",
                                      },
                                      on: { "click:clear": _vm.resetEndDate },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu1,
                        callback: function ($$v) {
                          _vm.menu1 = $$v
                        },
                        expression: "menu1",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "show-current": false,
                          color: "accent",
                          "no-title": "",
                          max: _vm.ad.end_date,
                        },
                        on: {
                          input: function ($event) {
                            _vm.menu1 = false
                          },
                        },
                        model: {
                          value: _vm.ad.start_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.ad, "start_date", $$v)
                          },
                          expression: "ad.start_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu2",
                      attrs: {
                        "close-on-content-click": false,
                        bottom: "",
                        "nudge-top": "25",
                        "nudge-left": 10,
                        transition: "scale-y-transition",
                        "offset-y": "",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      class: _vm.body,
                                      attrs: {
                                        value: _vm.formattedEnd,
                                        outlined: "",
                                        dense: "",
                                        "background-color": "input",
                                        label: "End Date",
                                        "prepend-inner-icon": "mdi-calendar",
                                        rules: [
                                          (v) => !!v || "End Date Required",
                                        ],
                                        readonly: "",
                                        clearable: "",
                                      },
                                      on: { "click:clear": _vm.resetEndDate },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu2,
                        callback: function ($$v) {
                          _vm.menu2 = $$v
                        },
                        expression: "menu2",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "show-current": false,
                          color: "accent",
                          "no-title": "",
                          min: _vm.ad.start_date,
                        },
                        on: {
                          input: function ($event) {
                            _vm.menu2 = false
                          },
                        },
                        model: {
                          value: _vm.ad.end_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.ad, "end_date", $$v)
                          },
                          expression: "ad.end_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-textarea", {
                    class: _vm.body,
                    attrs: {
                      outlined: "",
                      dense: "",
                      "background-color": "input",
                      label: "Notes (Optional)",
                      "no-resize": "",
                      rows: "3",
                      clearable: "",
                    },
                    model: {
                      value: _vm.ad.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.ad, "note", $$v)
                      },
                      expression: "ad.note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "fill" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "success white--text",
                  class: _vm.body,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.cloneAd.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Copy")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }